import React from 'react'
import { connect } from 'react-redux'


import HeroSlider, {
    Slide,
    Nav,
    OverlayContainer
} from 'hero-slider'

import { StyledOverlayContainer, StaticOverlayContainer, Explainer } from './style'

import imgLucky from './imgs/lucky.jpg'
import imgBold from './imgs/bold.jpg'
import imgAdventurous from './imgs/adventurous.jpg'
import imgYourself from './imgs/yourself.jpg'

class Home extends React.Component {


    render() {
        return (

            <HeroSlider
                slidingAnimation="left_to_right"
                orientation="horizontal"
                
                initialSlide={1}
                style={{
                    color: '#FFF',
                }}
                settings={{
                    slidingDuration: 400,
                    slidingDelay: 100,
                    shouldAutoplay: true,
                    shouldDisplayButtons: false,
                    autoplayDuration: 2000,
                    height: '100vh',
                }}
            >
                <Slide
                    background={{
                        backgroundColor: '#8A8A8A',
                        maskBackgroundBlendMode: 'luminosity',
                        backgroundImage: imgBold,
                        backgroundAnimation: 'fade',
                    }}
                >
                    <StyledOverlayContainer>
                        <div>
                            <h2>
          Be Bold.<br />Be Aliroulette.
                            </h2>
                        </div>
                    </StyledOverlayContainer>
                </Slide>

                <Slide
                    background={{
                        backgroundColor: '#8A8A8A',
                        maskBackgroundBlendMode: 'luminosity',
                        backgroundImage: imgAdventurous,
                        backgroundAnimation: 'fade',
                    }}
                >
                    <StyledOverlayContainer>
                        <div>
                            <h2>
          Be Adventurous.<br/>Be Aliroulette.
                            </h2>
                        </div>
                    </StyledOverlayContainer>
                </Slide>

                <Slide
                    background={{
                        backgroundColor: '#8A8A8A',
                        maskBackgroundBlendMode: 'luminosity',
                        backgroundImage: imgYourself,
                        backgroundAnimation: 'fade',
                    }}
                >
                    <StyledOverlayContainer>
                        <div>
                            <h2>
          Be Yourself.<br />Be Aliroulette.
                            </h2>
                        </div>
                    </StyledOverlayContainer>
                </Slide>

                <Slide
                    background={{
                        backgroundColor: '#8A8A8A',
                        maskBackgroundBlendMode: 'luminosity',
                        backgroundImage: imgLucky,
                        backgroundAnimation: 'fade',
                    }}
                >
                    <StyledOverlayContainer>
                        <div>
                            <h2>
          Be Lucky.<br />Be Aliroulette.
                            </h2>
                        </div>
                    </StyledOverlayContainer>
                </Slide>
                
                <StaticOverlayContainer>
                    <Explainer>
                        Aliroulette will change the way we order random items from aliexpress. The world will never be the same again.
                    </Explainer>
                </StaticOverlayContainer>
            </HeroSlider>
        )
    }
}


const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(Home)
