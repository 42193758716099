import styled from 'styled-components'
import { OverlayContainer } from 'hero-slider'
import { breakpoint } from '../../theme'

export const StyledOverlayContainer = styled(OverlayContainer)`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
   
    > div {
        text-align: left;
        width: 100%;

        ${breakpoint['md']`
            width: 850px;
        `}
    }
   
    h2 {
        margin: 0 14px;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 32px;

        ${breakpoint['sm']`
            margin: 0 18px;
            font-size: 38px;
        `}

        ${breakpoint['md']`
            margin: 0 36px;
            font-size: 72px;
        `}
  }
`

export const StaticOverlayContainer = styled(OverlayContainer)`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-end;
`

export const Explainer = styled.div`
  margin-bottom: 100px;
  margin-left: 14px;
  margin-right: 14px;
  font-weight: 600;
  font-size: 14px;
  text-shadow: 0px 0px 6px #000000;
`