import { css } from 'styled-components'

export const bPoints = {
    xs: '480px',
    sm: '768px',
    md: '992px',
    lg: '1200px'
}


export const breakpoint = Object.keys(bPoints).reduce((accumulator, label) => {
    accumulator[label] = (...args) => css`
		@media (min-width: ${bPoints[label]}) {
			${css(...args)};
		}
	`
    return accumulator
}, {})

export const colors = {
}
